/* General */
$fontSize:14px;
$borderRadius:8px;
$transitionDuration:.2s;

$sidebarWidth: 220px;
$sidebarMiniWidth: 90px;

$sidemenuColor: --primary-color;
$sidemenuBgColor: --surface-100;

$sidemenuActiveColor: --primary-color-text;
$sidemenuActiveBgColor: --primary-color;