//side bar styles
// body style
* {
	box-sizing: border-box;
}

html {
	height: 100%;
	font-size: $fontSize;
}

body {
	font-family: var(--font-family);
	color: var(--text-color);
	background-color: var(--surface-ground);
	margin: 0;
	padding: 0;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: var(--primary-color);
}

@import "./_mixins";
@import "./_splash";
@import "./_sidebar";
//@import "./_sidebar-mini";
@import "./_utils";
@import "./_typography";

//main content styles
.layout-main-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-between;
	padding: 6rem 2rem 2rem 2rem;
	transition: margin-left $transitionDuration;
}

.layout-main {
	flex: 1 1 auto;
}

// top bar style
.layout-topbar {
	position: fixed;
	height: 4rem;
	z-index: 997;
	left: 0;
	top: 0;
	width: 100%;
	padding: 0 1rem;
	background-color: var(--surface-card);
	transition: left $transitionDuration;
	display: flex;
	align-items: stretch;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);

	.layout-toggle-menu{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 1rem;
	}

	.layout-topbar-logo {
		display: flex;
		align-items: center;
		color: var(--surface-900);
		font-size: 1.5rem;
		font-weight: 500;
		img {
			height: 2.5rem;
			margin-right: .5rem;
		}

		&:focus {
			@include focused();
		}
	}	


	.layout-topbar-button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		transition: background-color $transitionDuration;

		&:hover {
			color: var(--text-color);
			background-color: rgba(0, 0, 0, .2);
		}

		&:focus {
			@include focused();
		}

		i {
			font-size: 1.5rem;
		}

		span {
			font-size: 1rem;
			display: none;
		}
	}

	.page-button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: relative;
		height: 3rem;
		cursor: pointer;
		transition: background-color $transitionDuration;

		&:hover {
			color: var(--text-color);
			background-color: var(--surface-hover);
		}

		&:focus {
			@include focused();
		}

		.pi {
			font-size: 1.5rem;
		}

		span {
			font-size: 1rem;
		}
	}

	.layout-topbar-menu {
		margin: 0 0 0 auto;
		padding: 0;
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;

		.layout-topbar-button {
			margin-left: 1rem;
		}
	}

	
}

// footer style
.layout-footer {
	transition: margin-left $transitionDuration;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 1rem;
	border-top: 1px solid var(--surface-border);
}

//lauout responsive
@media (max-width: 991px) {
	.layout-topbar {
		justify-content: space-between;
		.layout-toggle-menu{
			order: 2;
			margin-right: 0;
		}
		.layout-topbar-logo {
			width: auto;
			order: 0;
		}
		
		.layout-topbar-menu {
			order: 1;

			.page-button {
				order: 0;

				.p-button-label {
					display: none;
				}
			}

			.layout-menu-user-button {
				order: 2;
			}

			.layout-topbar-menu-button {
				order: 1;
			}
		}

	}
}

@media (min-width: 992px) {
	.layout-wrapper {
		&.layout-overlay {
			.layout-main-container {
				margin-left: 0;
				padding-left: 2rem;
			}

			.layout-sidebar {
				transform: translateX(-100%);
				left: 0;
				top: 0;
				height: 100vh;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&.layout-overlay-sidebar-active {
				.layout-sidebar {
					transform: translateX(0);
				}
			}
		}

		&.layout-static {
			.layout-main-container {
				margin-left: $sidebarWidth;
				&.mini{
					margin-left: $sidebarMiniWidth;
				}
			}
			&.layout-static-sidebar-inactive {
				.layout-sidebar {
					transform: translateX(-100%);
					left: 0;
				}

				.layout-main-container {
					margin-left: 0;
					padding-left: 2rem;
				}
			}
		}

		.layout-mask {
			display: none;
		}
	}
}

@media (max-width: 991px) {
	.layout-wrapper {
		.layout-main-container {
			margin-left: 0;
			padding-left: 2rem;
		}

		.layout-sidebar {
			transform: translateX(-100%);
			left: 0;
			top: 0;
			height: 100vh;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		.layout-mask {
			z-index: 998;
			background-color: var(--maskbg);

			&.layout-mask-enter-from,
			&.layout-mask-leave-to {
				background-color: transparent;
			}
		}

		&.layout-mobile-sidebar-active {
			.layout-sidebar {
				transform: translateX(0);
			}

			.layout-mask {
				display: block;
			}
		}
	}

	.body-overflow-hidden {
		overflow: hidden;
	}
}